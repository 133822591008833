import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
} from "@angular/core";
import { GridOptions } from "ag-grid-community";
import { ApiService } from "../../../services/api.service";
import { NbDateService } from "@nebular/theme";
import { Observable, of } from "rxjs";
import { FormControl } from "@angular/forms";
import { map, startWith } from "rxjs/operators";

@Component({
  selector: "ngx-vendors",
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: "./vendors.component.html",
  styleUrls: ["./vendors.component.scss"],
})
export class VendorsComponent implements OnInit {
  options: any[];
  filteredControlOptions$: Observable<string[]>;
  filteredNgModelOptions$: Observable<string[]>;
  inputFormControl: FormControl;
  value: string;
  private gridOptions: GridOptions;
  private gridApi;
  private gridColumnApi;
  values;
  includeCancelled: boolean;
  formpicker;
  selectedValue;
  selectedDates;
  vendorNames;
  model;
  rowData: any;
  columnDefs = [
    {
      headerName: "Dispatch Date",
      field: "dispatchDate",
      sortable: true,
      filter: "agDateColumnFilter",
      filterParams: {
        comparator: function (filterLocalDateAtMidnight, cellValue) {
          var dateAsString = new Date(cellValue).toLocaleDateString();
          if (dateAsString == null) return -1;
          var dateParts = dateAsString.split("/");
          var cellDate = new Date(
            Number(dateParts[2]),
            Number(dateParts[0]) - 1,
            Number(dateParts[1])
          );
          if (filterLocalDateAtMidnight.getTime() == cellDate.getTime()) {
            return 0;
          }
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          }
          if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          }
        },
        browserDatePicker: true,
        clearButton: true,
        applyButton: true,
        debounceMs: 200,
      },
      valueFormatter: this.dateFormatter,
    },
    {
      headerName: "Vendor Id",
      field: "vendorId",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Vendor Name",
      field: "vendorName",
      sortable: true,
      filter: true,
    },
    {
      headerName: "WO #",
      field: "workOrderNumber",
      sortable: true,
      filter: true,
      cellRenderer: this.UrlFormatter,
    },
    {
      headerName: "Vendor Cost",
      field: "vendorCost",
      sortable: true,
      filter: true,
      cellRenderer: this.CurrencyCellRenderer,
    },
    { headerName: "Address", field: "address", sortable: true, filter: true },
    { headerName: "City", field: "city", sortable: true, filter: true },
    { headerName: "State", field: "state", sortable: true, filter: true },
    { headerName: "Zip", field: "zip", sortable: true, filter: true },
    { headerName: "Trades", field: "trades", sortable: true, filter: true },
    { headerName: "Status", field: "status", sortable: true, filter: true },
    {
      headerName: "Business Types",
      field: "businessTypes",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Service Area",
      field: "serviceArea",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Vendor Status",
      field: "vendorStatus",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Paid By CC",
      field: "paidByCC",
      sortable: true,
      filter: true,
    },
  ];

  style = {
    width: "100%",
    height: "100%",
    boxSizing: "border-box",
  };
  @ViewChild("agGrid", { static: false }) agGrid;
  @ViewChild("autoInput", { static: false }) input;

  // private REST_API_SERVER = 'https://api.stungie.com/api/report/GetVendorReport';
  constructor(
    private apiService: ApiService,
    protected dateService: NbDateService<Date>
  ) {
    this.selectedDates = {
      start: this.dateService.addDay(this.monthStart, 0),
      end: this.dateService.addDay(this.monthEnd, 0),
    };
    this.includeCancelled = false;
  }

  ngOnInit() {
    // this.rowData = this.http.get('https://api.myjson.com/bins/15psn9');
    this.inputFormControl = new FormControl();
    this.getVendors().subscribe((res) => {
      this.vendorNames = res;
      this.options = res;
      this.filteredControlOptions$ = of(this.options);
      this.filteredNgModelOptions$ = of(this.options);
      this.filteredControlOptions$ = this.inputFormControl.valueChanges.pipe(
        startWith(""),
        map((filterString) => this.filter(filterString))
      );
    });
  }

  get monthStart(): Date {
    return this.dateService.getMonthStart(new Date());
  }

  get monthEnd(): Date {
    return this.dateService.getMonthEnd(new Date());
  }
  // public sendGetRequest() {
  //   return this.http.get(this.REST_API_SERVER);
  // }
  public dateFormatter(d) {
    return d.value ? new Date(d.value).toLocaleDateString() : "";
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  }

  Export(): void {
    let params = {
      columnGroups: true,
      allColumns: true,
      fileName: "export",
      // columnSeparator: document.querySelector('#columnSeparator').value
    };
    // console.log(params.columnSeparator);
    this.gridApi.exportDataAsCsv(params);
  }

  getVendors(): any {
    return this.apiService.sendGetRequest("report", "GetVendorNames");
  }

  resetValues(): void {
    this.selectedValue = undefined;
    this.selectedDates = undefined;
  }

  fetchData(): void {
    let startDate = this.selectedDates ? this.selectedDates.start : "";
    let endDate = this.selectedDates ? this.selectedDates.end : "";
    let params = {
      StartDate: startDate,
      EndDate: endDate,
      VendorId: this.selectedValue,
      IncludeCancelled: this.includeCancelled,
    };
    this.rowData = this.apiService.sendPostRequest(
      "report",
      "GetVendorReport",
      params
    );
    this.setWidthAndHeight("100%", "400px");
  }

  setWidthAndHeight(width, height) {
    this.style = {
      boxSizing: "border-box",
      width: width,
      height: height,
    };
  }
  autoSizeAll() {
    var allColumnIds = [];
    this.gridColumnApi.getAllColumns().forEach(function (column) {
      allColumnIds.push(column.colId);
    });
    this.gridColumnApi.autoSizeColumns(allColumnIds);
  }
  private UrlFormatter(params: any) {
    let workOrderId = params.data.workOrderId;
    let url =
      "http://portal.edcsg.com/WorkOrder/WorkOrderEditMain/" + workOrderId;
    return '<a href="' + url + '" target="_blank">' + params.value + "</a>";
  }
  private CurrencyCellRenderer(params: any) {
    var usdFormate = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    });
    return usdFormate.format(params.value);
  }
  private filter(value: string) {
    const filterValue = value.toLowerCase();

    let data = this.options.filter((x) =>
      x.name.toLowerCase().includes(filterValue)
    );
    return data;
  }

  // private filter(value: string): string[] {
  //   const filterValue = value.toLowerCase();
  //   let newArray = this.options.filter(function (el) {
  //    return el.name.toLowerCase().includes(filterValue);
  //   })
  //   //return this.options.filter(optionValue => optionValue.toLowerCase().includes(filterValue));
  // }

  onModelChange(selectedValue: string) {
    this.filteredNgModelOptions$ = of(this.filter(selectedValue));
    this.selectedValue = selectedValue;
  }
}
