import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AgGridModule } from "ag-grid-angular";
import { ReportsComponent } from "./reports.component";
import { VendorsComponent } from "./vendors/vendors.component";
import { ReportsRoutingModule } from "./reports-routing.module";
import {
  NbDatepickerModule,
  NbCardModule,
  NbSelectModule,
  NbButtonModule,
  NbCheckboxModule,
  NbAutocompleteModule,
} from "@nebular/theme";
import { NbDateFnsDateModule } from "@nebular/date-fns";
import { VendorlistComponent } from "./vendorlist/vendorlist.component";
import { InvoiceComponent } from "./invoice/invoice.component";
import { WorkorderlistComponent } from "./workorderlist/workorderlist.component";
import { JobsitesComponent } from "./jobsites/jobsites.component";
import { CancelledWorkorderlistComponent } from "./cancelledworkorderlist/cancelledworkorderlist.component";
import { WorkorderlistDuedateComponent } from "./workorderlist-duedate/workorderlist-duedate.component";
import { WorkorderlistMissedDuedateComponent } from "./workorderlist-missedduedate/workorderlist-missedduedate.component";
import { PerformanceComponent } from "./performance/performance.component";
import { CwopComponent } from "./cwop/cwop.component";
import { TammyComponent } from "./tammy/tammy.component";
import { AgingbystatusComponent } from "./agingbystatus/agingbystatus.component";
import { CompletedagingComponent } from "./completedaging/completedaging.component";
import { AgingbydispatcherComponent } from "./agingbydispatcher/agingbydispatcher.component";
import { StagnantjobComponent } from "./stagnantjob/stagnantjob.component";
import { VendorstatusComponent } from "./vendorstatus/vendorstatus.component";
import { VendorstatusCompletedComponent } from "./vendorstatus-completed/vendorstatus-completed.component";
import { VendorstatusIncompleteComponent } from "./vendorstatus-incomplete/vendorstatus-incomplete.component";
@NgModule({
  imports: [
    CommonModule,
    ReportsRoutingModule,
    FormsModule,
    NbCardModule,
    NbSelectModule,
    NbButtonModule,
    NbCheckboxModule,
    ReactiveFormsModule,
    AgGridModule.withComponents([]),
    NbDatepickerModule,
    NbDateFnsDateModule,
    NbAutocompleteModule,
  ],
  declarations: [
    ReportsComponent,
    VendorsComponent,
    VendorlistComponent,
    InvoiceComponent,
    WorkorderlistComponent,
    JobsitesComponent,
    CancelledWorkorderlistComponent,
    WorkorderlistDuedateComponent,
    WorkorderlistMissedDuedateComponent,
    PerformanceComponent,
    CwopComponent,
    TammyComponent,
    AgingbystatusComponent,
    CompletedagingComponent,
    AgingbydispatcherComponent,
    StagnantjobComponent,
    VendorstatusComponent,
    VendorstatusCompletedComponent,
    VendorstatusIncompleteComponent,
  ],
})
export class ReportsModule {}
