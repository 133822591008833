import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  private REST_API_SERVER = "https://api.stungie.com/api/";
  // private REST_API_SERVER = "http://localhost:55487/api/";
  constructor(private http: HttpClient) {}

  todayDate() {
    let nDate = new Date();
    return nDate;
  }

  sendGetRequest(controller: string, func: string) {
    let path = controller.concat("/").concat(func);
    return this.http.get(this.REST_API_SERVER.concat(path));
  }

  sendPostRequest(controller: string, func: string, params: any) {
    let path = controller.concat("/").concat(func);
    return this.http.post(this.REST_API_SERVER.concat(path), params);
  }
}
