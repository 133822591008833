import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
} from "@angular/core";
import { GridOptions } from "ag-grid-community";
import { ApiService } from "../../../services/api.service";

@Component({
  selector: "ngx-performance",
  templateUrl: "./performance.component.html",
  styleUrls: ["./performance.component.scss"],
})
export class PerformanceComponent implements OnInit {
  private gridOptions: GridOptions;
  private gridApi;
  private gridColumnApi;
  rowData: any;
  model;
  columnDefs = [
    {
      headerName: "WorkOrder Number",
      field: "workOrderNumber",
      sortable: true,
      filter: true,
    },
    { headerName: "TBS_Date", field: "tbS_Date", sortable: true, filter: true },
    { headerName: "TBS_CCC", field: "tbS_CCC", sortable: true, filter: true },
    {
      headerName: "Dispatched_Date",
      field: "dispatched_Date",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Dispatched_CCC",
      field: "dispatched_CCC",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Pending_Proposal_Vendor_Date",
      field: "pending_Proposal_Vendor_Date",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Pending_Proposal_Vendor_CCC",
      field: "pending_Proposal_Vendor_CCC",
      sortable: true,
      filter: true,
    },
    {
      headerName: "In_Process_Date",
      field: "in_Process_Date",
      sortable: true,
      filter: true,
    },
    {
      headerName: "In_Process_CCC",
      field: "in_Process_CCC",
      sortable: true,
      filter: true,
    },
    { headerName: "CAR_Date", field: "caR_Date", sortable: true, filter: true },
    { headerName: "CAR_CCC", field: "caR_CCC", sortable: true, filter: true },
    {
      headerName: "Completed_Date",
      field: "completed_Date",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Completed_CCC",
      field: "completed_CCC",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Denied_Date",
      field: "denied_Date",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Denied_CCC",
      field: "denied_CCC",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Cancelled_Date",
      field: "cancelled_Date",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Cancelled_CCC",
      field: "cancelled_CCC",
      sortable: true,
      filter: true,
    },
    { headerName: "WOA_Date", field: "woA_Date", sortable: true, filter: true },
    { headerName: "WOA_CCC", field: "woA_CCC", sortable: true, filter: true },
    { headerName: "POH_Date", field: "poH_Date", sortable: true, filter: true },
    { headerName: "POH_CCC", field: "poH_CCC", sortable: true, filter: true },
    { headerName: "TOS_Date", field: "toS_Date", sortable: true, filter: true },
    { headerName: "TOS_CCC", field: "toS_CCC", sortable: true, filter: true },
    { headerName: "WOP_Date", field: "woP_Date", sortable: true, filter: true },
    { headerName: "WOP_CCC", field: "woP_CCC", sortable: true, filter: true },
    { headerName: "NOD_Date", field: "noD_Date", sortable: true, filter: true },
    { headerName: "NOD_CCC", field: "noD_CCC", sortable: true, filter: true },
    { headerName: "RTB_Date", field: "rtB_Date", sortable: true, filter: true },
    { headerName: "RTB_CCC", field: "rtB_CCC", sortable: true, filter: true },
    {
      headerName: "Pending_Proposal_EDC_Date",
      field: "pending_Proposal_EDC_Date",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Pending_Proposal_EDC_CCC",
      field: "pending_Proposal_EDC_CCC",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Billing_Pending_Date",
      field: "billing_Pending_Date",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Billing_Pending_CCC",
      field: "billing_Pending_CCC",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Invoice_Date",
      field: "invoice_Date",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Invoice_CCC",
      field: "invoice_CCC",
      sortable: true,
      filter: true,
    },
    {
      headerName: "CWOP_Date",
      field: "cwoP_Date",
      sortable: true,
      filter: true,
    },
    {
      headerName: "CWOP_CCC",
      field: "cwoP_CCC",
      sortable: true,
      filter: true,
    },
    {
      headerName: "WorkOrderId",
      field: "workOrderId",
      sortable: true,
      filter: true,
    },
  ];
  style = {
    width: "100%",
    height: "100%",
    boxSizing: "border-box",
  };
  @ViewChild("agGrid", { static: false }) agGrid;
  constructor(private apiService: ApiService) {}

  ngOnInit() {}

  fetchData(): any {
    this.rowData = this.apiService.sendGetRequest(
      "workorder",
      "GetWorkOrderStatusChanges"
    );

    this.setWidthAndHeight("100%", "400px");
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  }

  Export(): void {
    let params = {
      columnGroups: true,
      allColumns: true,
      fileName: "export",
      // columnSeparator: document.querySelector('#columnSeparator').value
    };
    // console.log(params.columnSeparator);
    this.gridApi.exportDataAsCsv(params);
  }
  setWidthAndHeight(width, height) {
    this.style = {
      boxSizing: "border-box",
      width: width,
      height: height,
    };
  }
  autoSizeAll() {
    var allColumnIds = [];
    this.gridColumnApi.getAllColumns().forEach(function (column) {
      allColumnIds.push(column.colId);
    });
    this.gridColumnApi.autoSizeColumns(allColumnIds);
  }
}
