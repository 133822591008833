import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild
} from "@angular/core";
import { GridOptions } from "ag-grid-community";
import { ApiService } from "../../../services/api.service";
import { throwIfAlreadyLoaded } from "../../../@core/module-import-guard";

@Component({
  selector: "ngx-jobsites",
  templateUrl: "./jobsites.component.html",
  styleUrls: ["./jobsites.component.scss"]
})
export class JobsitesComponent implements OnInit {
  private gridOptions: GridOptions;
  private gridApi;
  private gridColumnApi;
  fullWidth: boolean = true;
  values;
  formpicker;
  selectedValue;
  selectedDates;
  customerNames;
  model;
  rowData: any;
  columnDefs = [
    {
      headerName: "Customer Name",
      field: "companyName",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Name",
      field: "name",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Location",
      field: "locationId",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Address",
      field: "street_Address",
      sortable: true,
      filter: true,
    },
    {
      headerName: "City",
      field: "city",
      sortable: true,
      filter: true,
    },
    {
      headerName: "State",
      field: "stateCode",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Zip",
      field: "zip",
      sortable: true,
      filter: true,
    }
  ];

  style = {
    width: "100%",
    height: "100%",
    boxSizing: "border-box"
  };
  @ViewChild("agGrid", { static: false }) agGrid;
  // private REST_API_SERVER = 'https://api.stungie.com/api/report/GetVendorReport';
  constructor(private apiService: ApiService) { }

  ngOnInit() {
    this.getCustomers().subscribe(res => (this.customerNames = res));
  }

  getCustomers(): any {
    return this.apiService.sendGetRequest("customer", "GetCustomerList");
  }
  public dateFormatter(d) {
    return d.value ? new Date(d.value).toLocaleDateString() : "";
  }

  public currencyFormatter(d) {
    return d.value ? "$" + d.value : 0;
  }
  private UrlFormatter(params: any) {
    let workOrderId = params.data.workOrderId;
    let url =
      "http://portal.edcsg.com/WorkOrder/WorkOrderEditMain/" + workOrderId;
    return '<a href="' + url + '" target="_blank">' + params.value + "</a>";
  }
  private CurrencyCellRenderer(params: any) {
    var usdFormate = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2
    });
    return usdFormate.format(params.value);
  }

  private RoundingCellRender(params: any) {
    return Number(params.value.toFixed(2));
  }

  public calcNetProfit(d) { }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  }

  Export(): void {
    let params = {
      columnGroups: true,
      allColumns: true,
      fileName: "export"
      // columnSeparator: document.querySelector('#columnSeparator').value
    };
    // console.log(params.columnSeparator);
    this.gridApi.exportDataAsCsv(params);
  }

  resetValues(): void {
    this.selectedValue = undefined;
    this.selectedDates = undefined;
  }

  fetchData(): void {
    this.getJobSites();

    //this.rowData = this.apiService.sendGetRequest('report','GetVendorReport');
    //this.rowData = this.setWidthAndHeight("100%", "400px");
  }

  getJobSites(): void {
    this.rowData = this.apiService.sendGetRequest("customer", "GetJobSiteList/" + this.selectedValue);
    this.setWidthAndHeight("100%", "400px");
  }

  setWidthAndHeight(width, height) {
    this.style = {
      boxSizing: "border-box",
      width: width,
      height: height
    };
  }
  autoSizeAll() {
    var allColumnIds = [];
    this.gridColumnApi.getAllColumns().forEach(function (column) {
      allColumnIds.push(column.colId);
    });
    this.gridColumnApi.autoSizeColumns(allColumnIds);
  }
}
