import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
} from "@angular/core";
import { GridOptions } from "ag-grid-community";
import { ApiService } from "../../../services/api.service";
import { NbCalendarRange, NbDateService } from "@nebular/theme";

@Component({
  selector: "ngx-workorderlist",
  templateUrl: "./workorderlist.component.html",
  styleUrls: ["./workorderlist.component.scss"],
})
export class WorkorderlistComponent implements OnInit {
  private gridOptions: GridOptions;
  private gridApi;
  private gridColumnApi;
  values;
  formpicker;
  selectedValue;
  selectedDates;
  vendorNames;
  model;
  rowData: any;
  columnDefs = [
    {
      headerName: "WO #",
      field: "workOrderDisplayId",
      sortable: true,
      filter: true,
      cellRenderer: this.UrlFormatter,
    },
    { headerName: "PO #", field: "poNumber", sortable: true, filter: true },
    { headerName: "Site #", field: "siteNumber", sortable: true, filter: true },
    { headerName: "Address", field: "address", sortable: true, filter: true },
    { headerName: "City", field: "city", sortable: true, filter: true },
    { headerName: "State", field: "state", sortable: true, filter: true },
    {
      headerName: "Received Date",
      field: "receivedDate",
      sortable: true,
      filter: true,
      valueFormatter: this.dateFormatter,
    },
    {
      headerName: "Last Updated",
      field: "lastUpdated",
      sortable: true,
      filter: true,
      valueFormatter: this.dateFormatter,
    },
    {
      headerName: "ETA",
      field: "promisedDate",
      sortable: true,
      filter: true,
      valueFormatter: this.dateFormatter,
    },
    {
      headerName: "NTE",
      field: "nte",
      sortable: true,
      filter: true,
      cellRenderer: this.currencyFormatter,
    },
    {
      headerName: "Invoice",
      field: "invoicedAmount",
      sortable: true,
      filter: true,
      cellRenderer: this.currencyFormatter,
    },
    {
      headerName: "Priority",
      field: "priorityName",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Date Completed",
      field: "dateCompleted",
      sortable: true,
      filter: true,
      valueFormatter: this.dateFormatter,
    },
    {
      headerName: "Dispatcher",
      field: "dispatcherName",
      sortable: true,
      filter: true,
    },
    { headerName: "Status", field: "status", sortable: true, filter: true },
    { headerName: "Trades", field: "trade", sortable: true, filter: true },
  ];

  style = {
    width: "100%",
    height: "100%",
    boxSizing: "border-box",
  };
  @ViewChild("agGrid", { static: false }) agGrid;
  // private REST_API_SERVER = 'https://api.stungie.com/api/report/GetVendorReport';
  constructor(
    private apiService: ApiService,
    protected dateService: NbDateService<Date>
  ) {
    this.selectedDates = {
      start: this.dateService.addDay(this.monthStart, 0),
      end: this.dateService.addDay(this.monthEnd, 0),
    };
  }

  ngOnInit() {
    // this.rowData = this.http.get('https://api.myjson.com/bins/15psn9');
    //this.getInvoices().subscribe(res => this.vendorNames = res);
  }

  get monthStart(): Date {
    return this.dateService.getMonthStart(new Date());
  }

  get monthEnd(): Date {
    return this.dateService.getMonthEnd(new Date());
  }
  // public sendGetRequest() {
  //   return this.http.get(this.REST_API_SERVER);
  // }
  public dateFormatter(d) {
    return d.value ? new Date(d.value).toLocaleDateString() : "";
  }

  public currencyFormatter(d) {
    return d.value ? "$" + d.value : 0;
  }
  private UrlFormatter(params: any) {
    let workOrderId = params.data.workOrderId;
    let url =
      "https://portal.edcsg.com/WorkOrder/WorkOrderEditMain/" + workOrderId;
    return '<a href="' + url + '" target="_blank">' + params.value + "</a>";
  }
  private CurrencyCellRenderer(params: any) {
    var usdFormate = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    });
    return usdFormate.format(params.value);
  }

  private RoundingCellRender(params: any) {
    return Number(params.value.toFixed(2));
  }

  public calcNetProfit(d) {}
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  }

  Export(): void {
    let params = {
      columnGroups: true,
      allColumns: true,
      fileName: "export",
      // columnSeparator: document.querySelector('#columnSeparator').value
    };
    // console.log(params.columnSeparator);
    this.gridApi.exportDataAsCsv(params);
  }

  resetValues(): void {
    this.selectedValue = undefined;
    this.selectedDates = undefined;
  }

  fetchData(): void {
    let startDate = this.selectedDates ? this.selectedDates.start : "";
    let endDate = this.selectedDates ? this.selectedDates.end : "";
    let params = {
      StartDate: startDate,
      EndDate: endDate,
    };
    // let r = this.apiService.sendGetRequest('report','GetVendorReport').subscribe(res => {
    //   this.rowData = res;
    // })

    this.rowData = this.apiService.sendPostRequest(
      "workorder",
      "GetWorkOrderList",
      params
    );

    //this.rowData = this.apiService.sendGetRequest('report','GetVendorReport');
    this.setWidthAndHeight("100%", "400px");
  }

  setWidthAndHeight(width, height) {
    this.style = {
      boxSizing: "border-box",
      width: width,
      height: height,
    };
  }
  autoSizeAll() {
    var allColumnIds = [];
    this.gridColumnApi.getAllColumns().forEach(function (column) {
      allColumnIds.push(column.colId);
    });
    this.gridColumnApi.autoSizeColumns(allColumnIds);
  }
}
