import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
} from "@angular/core";
import { GridOptions } from "ag-grid-community";
import { ApiService } from "../../../services/api.service";

@Component({
  selector: "ngx-vendorlist",
  templateUrl: "./vendorlist.component.html",
  styleUrls: ["./vendorlist.component.scss"],
})
export class VendorlistComponent implements OnInit {
  private gridOptions: GridOptions;
  private gridApi;
  private gridColumnApi;
  vendorList;
  rowData: any;
  model;
  columnDefs = [
    {
      headerName: "Vendor Id",
      field: "vendorId",
      sortable: true,
      filter: true,
      cellRenderer: this.UrlFormatter,
    },
    { headerName: "Vendor Name", field: "name", sortable: true, filter: true },
    { headerName: "Address", field: "address", sortable: true, filter: true },
    { headerName: "City", field: "city", sortable: true, filter: true },
    { headerName: "State", field: "state", sortable: true, filter: true },
    { headerName: "Zip", field: "zip", sortable: true, filter: true },
    { headerName: "Phone", field: "phone", sortable: true, filter: true },
    {
      headerName: "Email",
      field: "emailAddress",
      sortable: true,
      filter: true,
    },
    {
      headerName: "WO_Count",
      field: "workOrderCount",
      sortable: true,
      filter: true,
    },
    { headerName: "Trades", field: "trades", sortable: true, filter: true },
    {
      headerName: "Created Date",
      field: "createdDate",
      sortable: true,
      filter: true,
      valueFormatter: this.dateFormatter,
    },
    {
      headerName: "GL Expire Date",
      field: "glInsExpireDate",
      sortable: true,
      filter: true,
    },
    {
      headerName: "WC Expire Date",
      field: "wcInsExpireDate",
      sortable: true,
      filter: true,
    },
    {
      headerName: "NationWide",
      field: "isNationWide",
      sortable: true,
      filter: true,
    },
    {
      headerName: "StateWide",
      field: "isStateWide",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Badge Req",
      field: "isBadgeRequired",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Profile Date",
      field: "saDate",
      sortable: true,
      filter: true,
    },
    {
      headerName: "W9 Date",
      field: "w9Date",
      sortable: true,
      filter: true,
    },
    {
      headerName: "COI Req",
      field: "isCOIRequired",
      sortable: true,
      filter: true,
    },
    {
      headerName: "WC Req",
      field: "isWCRequired",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Quote Only",
      field: "isQuoteOnly",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Status",
      field: "vendorStatus",
      sortable: true,
      filter: true,
    },
    { headerName: "Active", field: "isActive", sortable: true, filter: true },
    {
      headerName: "Preferred",
      field: "isPreferredVendor",
      sortable: true,
      filter: true,
    },
    { headerName: "Terms", field: "terms", sortable: true, filter: true },
    { headerName: "Tier", field: "tier", sortable: true, filter: true },
    { headerName: "SSN", field: "ssn", sortable: true, filter: true },
    {
      headerName: "MissingRates",
      field: "missingRates",
      sortable: true,
      filter: true,
    },
  ];

  style = {
    width: "100%",
    height: "100%",
    boxSizing: "border-box",
  };
  @ViewChild("agGrid", { static: false }) agGrid;
  constructor(private apiService: ApiService) {}

  ngOnInit() {
    this.rowData = this.getVendors();
    // this.getVendors().subscribe(res => this.vendorList = res);
  }

  getVendors(): any {
    return this.apiService.sendGetRequest("vendor", "GetVendorList");
  }

  private UrlFormatter(params: any) {
    let vendorId = params.data.vendorId;
    let url = "http://portal.edcsg.com/Vendor/Main/" + vendorId;
    return '<a href="' + url + '" target="_blank">' + params.value + "</a>";
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
    this.autoSizeAll();
  }

  Export(): void {
    let params = {
      columnGroups: true,
      allColumns: true,
      fileName: "export",
      // columnSeparator: document.querySelector('#columnSeparator').value
    };
    // console.log(params.columnSeparator);
    this.gridApi.exportDataAsCsv(params);
  }

  autoSizeAll() {
    var allColumnIds = [];
    this.gridColumnApi.getAllColumns().forEach(function (column) {
      allColumnIds.push(column.colId);
    });
    this.gridColumnApi.autoSizeColumns(allColumnIds);
  }
  public dateFormatter(d) {
    return d.value ? new Date(d.value).toLocaleDateString() : "";
  }
}
