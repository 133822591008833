import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { GridOptions } from "ag-grid-community";
@Component({
  selector: 'ngx-cwop',
  templateUrl: './cwop.component.html',
  styleUrls: ['./cwop.component.scss']
})
export class CwopComponent implements OnInit {
  fullWidth: boolean = true;
  private gridApi;
  private gridColumnApi;
  rowData: any;
  columnDefs = [
    {
      headerName: "WorkOrder Number",
      field: "workOrderNumber",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Status",
      statusId: "statusId",
      sortable: true,
      filter: true,
    },
    {
      headerName: "CWOP Date",
      field: "cwoP_Date",
      sortable: true,
      filter: true,
      valueFormatter: this.dateFormatter
    },
    {
      headerName: "Proposal Created Date",
      field: "proposal_Created_Date",
      sortable: true,
      filter: true,
      valueFormatter: this.dateFormatter
    },
    {
      headerName: "Proposal Modified Date",
      field: "proposal_Modified_Date",
      sortable: true,
      filter: true,
      valueFormatter: this.dateFormatter
    }
  ];

  style = {
    width: "100%",
    height: "100%",
    boxSizing: "border-box"
  };
  @ViewChild("agGrid", { static: false }) agGrid;
  constructor(private apiService: ApiService) { }

  ngOnInit() {
  }

  public dateFormatter(d) {
    return d.value ? new Date(d.value).toLocaleDateString() : "";
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  }
  Export(): void {
    let params = {
      columnGroups: true,
      allColumns: true,
      fileName: "export"
      // columnSeparator: document.querySelector('#columnSeparator').value
    };
    // console.log(params.columnSeparator);
    this.gridApi.exportDataAsCsv(params);
  }

  fetchData(): void {
    this.rowData = this.apiService.sendGetRequest("WorkOrder", "GetCWOPs");
    this.setWidthAndHeight("100%", "400px");
  }

  setWidthAndHeight(width, height) {
    this.style = {
      boxSizing: "border-box",
      width: width,
      height: height
    };
  }

  autoSizeAll() {
    var allColumnIds = [];
    this.gridColumnApi.getAllColumns().forEach(function (column) {
      allColumnIds.push(column.colId);
    });
    this.gridColumnApi.autoSizeColumns(allColumnIds);
  }
}
