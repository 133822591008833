import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
} from "@angular/core";
import { GridOptions } from "ag-grid-community";
import { ApiService } from "../../../services/api.service";
import { NbCalendarRange, NbDateService } from "@nebular/theme";

@Component({
  selector: "ngx-vendorstatus-completed",
  templateUrl: "./vendorstatus-completed.component.html",
  styleUrls: ["./vendorstatus-completed.component.scss"],
})
export class VendorstatusCompletedComponent implements OnInit {
  private gridOptions: GridOptions;
  private gridApi;
  private gridColumnApi;
  values;
  formpicker;
  selectedValue;
  selectedDates;
  vendorNames;
  model;
  rowData: any;
  columnDefs = [
    {
      headerName: "Vendor Name",
      field: "name",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Email Address",
      field: "emailAddress",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Status",
      field: "vendorStatus",
      sortable: true,
      filter: true,
    },
    {
      headerName: "WO Count",
      field: "workOrderCount",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Created Date",
      field: "createdDate",
      sortable: true,
      filter: true,
      valueFormatter: this.dateFormatter,
    },
    {
      headerName: "G/L Expiration Date",
      field: "glInsExpireDate",
      sortable: true,
      filter: true,
      valueFormatter: this.dateFormatter,
    },
    {
      headerName: "W/C Expiration Date",
      field: "wcInsExpireDate",
      sortable: true,
      filter: true,
      valueFormatter: this.dateFormatter,
    },
    {
      headerName: "W/C Exempt",
      field: "isWCRequired",
      sortable: true,
      filter: true,
    },
    {
      headerName: "W-9 Date",
      field: "w9Date",
      sortable: true,
      filter: true,
      valueFormatter: this.dateFormatter,
    },
    {
      headerName: "Profile Date",
      field: "saDate",
      sortable: true,
      filter: true,
      valueFormatter: this.dateFormatter,
    },
  ];
  style = {
    width: "100%",
    height: "100%",
    boxSizing: "border-box",
  };
  @ViewChild("agGrid", { static: false }) agGrid;
  constructor(
    private apiService: ApiService,
    protected dateService: NbDateService<Date>
  ) {}

  ngOnInit() {
    this.rowData = this.getData();
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  }
  getData(): any {
    return this.apiService.sendGetRequest("report", "GetVendorStatusComplete");
  }
  Export(): void {
    let params = {
      columnGroups: true,
      allColumns: true,
      fileName: "completedvendors",
      // columnSeparator: document.querySelector('#columnSeparator').value
    };
    // console.log(params.columnSeparator);
    this.gridApi.exportDataAsCsv(params);
  }
  public dateFormatter(d) {
    return d.value ? new Date(d.value).toLocaleDateString() : "";
  }
  setWidthAndHeight(width, height) {
    this.style = {
      boxSizing: "border-box",
      width: width,
      height: height,
    };
  }
  autoSizeAll() {
    var allColumnIds = [];
    this.gridColumnApi.getAllColumns().forEach(function (column) {
      allColumnIds.push(column.colId);
    });
    this.gridColumnApi.autoSizeColumns(allColumnIds);
  }
}
