import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ReportsComponent } from "./reports.component";
import { VendorsComponent } from "./vendors/vendors.component";
import { VendorlistComponent } from "./vendorlist/vendorlist.component";
import { InvoiceComponent } from "./invoice/invoice.component";
import { WorkorderlistComponent } from "./workorderlist/workorderlist.component";
import { WorkorderlistDuedateComponent } from "./workorderlist-duedate/workorderlist-duedate.component";
import { WorkorderlistMissedDuedateComponent } from "./workorderlist-missedduedate/workorderlist-missedduedate.component";
import { CancelledWorkorderlistComponent } from "./cancelledworkorderlist/cancelledworkorderlist.component";
import { JobsitesComponent } from "./jobsites/jobsites.component";
import { PerformanceComponent } from "./performance/performance.component";
import { CwopComponent } from "./cwop/cwop.component";
import { AgingbystatusComponent } from "./agingbystatus/agingbystatus.component";
import { CompletedagingComponent } from "./completedaging/completedaging.component";
import { StagnantjobComponent } from "./stagnantjob/stagnantjob.component";
import { VendorstatusComponent } from "./vendorstatus/vendorstatus.component";
import { VendorstatusIncompleteComponent } from "./vendorstatus-incomplete/vendorstatus-incomplete.component";
import { VendorstatusCompletedComponent } from "./vendorstatus-completed/vendorstatus-completed.component";
const routes: Routes = [
  {
    path: "",
    component: ReportsComponent,
    children: [
      {
        path: "invoice",
        component: InvoiceComponent,
      },
      {
        path: "vendors",
        component: VendorsComponent,
      },
      {
        path: "vendorlist",
        component: VendorlistComponent,
      },
      {
        path: "workorderlist",
        component: WorkorderlistComponent,
      },
      {
        path: "workorderlist-duedate",
        component: WorkorderlistDuedateComponent,
      },
      {
        path: "workorderlist-missedduedate",
        component: WorkorderlistMissedDuedateComponent,
      },
      {
        path: "cancelledworkorderlist",
        component: CancelledWorkorderlistComponent,
      },
      {
        path: "jobsites",
        component: JobsitesComponent,
      },
      {
        path: "performance",
        component: PerformanceComponent,
      },
      {
        path: "cwop",
        component: CwopComponent,
      },
      {
        path: "agingbystatus",
        component: AgingbystatusComponent,
      },
      {
        path: "completedagingbystatus",
        component: CompletedagingComponent,
      },
      {
        path: "stagnantjob",
        component: StagnantjobComponent,
      },
      {
        path: "vendorstatusall",
        component: VendorstatusComponent,
      },
      {
        path: "vendorstatuscomplete",
        component: VendorstatusCompletedComponent,
      },
      {
        path: "vendorstatusincomplete",
        component: VendorstatusIncompleteComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ReportsRoutingModule {}
